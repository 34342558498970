import { ClipboardIcon } from '@heroicons/react/20/solid';
import { useContext, useEffect, useState } from 'react';

import {
  ConnectButton,
  Header,
  InstallFlaskButton,
  SendTransactionButton,
  ViewWalletButton,
  SendTransactionModal,
  ViewWalletModal,
  CreateWalletModal,
} from '../components';
import { defaultSnapOrigin } from '../config';
import { MetamaskActions, MetaMaskContext } from '../hooks';
import {
  connectSnap,
  createNewWallet,
  getBalance,
  getInfo,
  getSnap,
  isLocalSnap,
  shouldDisplayReconnectButton,
} from '../utils';

const validateCreateWalletState = (walletInfo: any): object => {
  const errors: any = {};

  if (!walletInfo.name) {
    errors.name = 'Name is required';
  }

  if (walletInfo.seed && !/^[a-z]{55}$/u.test(walletInfo.seed)) {
    errors.seed = 'Seed must be 55 lowercase letters';
  }

  return errors;
};
const Background = () => (
  <div className="absolute inset-0 overflow-hidden z-0 pointer-events-none">
    <div className="absolute inset-0 flex items-center justify-center">
      {/* Original background */}
      <div
        className="absolute inset-x-0 bottom-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
    <div className="absolute inset-0 flex items-center justify-center">
      {/* Reflected background */}
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative right-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tl from-[#ff80b5] to-[#9089fc] opacity-30 sm:right-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(25.9% 44.1%, 0% 61.6%, 2.5% 26.9%, 14.5% 0.1%, 19.3% 2%, 27.5% 32.5%, 39.8% 62.4%, 47.6% 68.1%, 52.5% 58.3%, 54.8% 34.5%, 72.5% 76.7%, 99.9% 64.9%, 82.1% 100%, 72.4% 76.8%, 23.9% 97.7%, 25.9% 44.1%)',
          }}
        />
      </div>
    </div>
  </div>
);

const Container = ({ children }: { children: React.ReactNode }) => (
  <div
    className={`relative flex flex-col items-center flex-1 pt-12 bg-gradient-to-br from-slate-950 to-slate-900`}
  >
    <Background />
    {children}
  </div>
);

const Card = ({
  content,
  fullWidth,
}: {
  content: {
    title: string;
    balance?: string;
    description: string;
    button: React.ReactNode;
  };
  disabled?: boolean;
  fullWidth?: boolean;
}) => (
  <div
    className={`flex flex-col items-center justify-center p-6 min-w-[20vh] lg:min-w-[60vh]  min-h-[40vh] border border-slate-800 bg-slate-900 rounded-lg text-center max-w-4xl w-full sm:p-4 sm:max-w-full ${
      fullWidth ? 'sm:w-full' : 'sm:w-96'
    }`}
  >
    <div className="flex flex-col items-center justify-center px-6 mb-6 rounded-lg">
      <h2 className="text-white text-[3vh] font-bold mb-2">{content.title}</h2>
    </div>
    <div className="text-white text-[1.5vh] mb-4 font-semibold border-none bg-transparent">
      <div className="flex flex-row items-center justify-center space-x-2">
        <span>{content.description}</span>
      </div>
    </div>
    <div className="mt-12">{content.button}</div>
  </div>
);

const WalletCard = ({
  content,
  fullWidth,
}: {
  content: {
    title: string;
    balance?: string;
    description: string;
    button: React.ReactNode;
  };
  disabled?: boolean;
  fullWidth?: boolean;
}) => {
  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .catch((error) => console.error('Error copying to clipboard', error));
  };

  return (
    <div
      className={`flex flex-col items-center justify-center p-6 min-w-[40vh] lg:min-w-[70vh]  min-h-[40vh] border border-slate-800 bg-slate-900 rounded-lg text-center max-w-4xl w-full sm:p-4 sm:max-w-full ${
        fullWidth ? 'sm:w-full' : 'sm:w-96'
      }`}
    >
      <div className="flex flex-col items-center justify-center px-6 mb-6 border border-2 border-gray-700 rounded-lg">
        <h2 className="text-white text-[3vh] font-bold mb-2">
          {content.title}
        </h2>
      </div>
      <button className="text-white text-[1vh] lg:text-[1.5vh] mb-4 font-semibold border-none outline-none hover:border-none hover:text-gray-400 bg-transparent">
        <div
          onClick={() => handleCopyToClipboard(content.description)}
          className="flex flex-row items-center justify-center space-x-2"
        >
          <span>{content.description}</span>
          <ClipboardIcon className="h-6 w-6" aria-hidden="true" />
        </div>
      </button>
      {content.balance && (
        <div className="flex justify-center bg-slate-950 rounded-xl p-6 border border-2 border-slate-700">
          <p className="text-white text-[2.5vh] font-bold">
            {content.balance} qus
          </p>
        </div>
      )}
      <div className="mt-12">{content.button}</div>
    </div>
  );
};

const Notice = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-indigo-100 border border-indigo-600 text-indigo-900 rounded-lg text-center p-6 mt-6 max-w-4xl w-full sm:mt-3 sm:p-4">
    {children}
  </div>
);

const ErrorMessage = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-red-100 border border-red-500 text-red-900 rounded-lg p-6 mb-6 mt-6 max-w-4xl w-full sm:p-4 sm:mb-3 sm:mt-3 sm:max-w-full">
    {children}
  </div>
);

const Index = () => {
  const [currentInfo, setCurrentInfo] = useState<any>({});
  const [currentBalance, setCurrentBalance] = useState<string>('0');
  const [selectedIdPackage, setSelectedIdPackage] = useState<any>({});
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [createWalletModalOpen, setCreateWalletModalOpen] = useState(false);
  const [walletInfo, setWalletInfo] = useState<any>({});

  const [state, dispatch] = useContext(MetaMaskContext);

  const isMetaMaskReady = isLocalSnap(defaultSnapOrigin)
    ? state.isFlask
    : state.snapsDetected;

  const handleConnectClick = async () => {
    try {
      await connectSnap();
      const installedSnap = await getSnap();

      dispatch({
        type: MetamaskActions.SetInstalled,
        payload: installedSnap,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: MetamaskActions.SetError, payload: error });
    }
  };

  const handleGetInfo = async () => {
    try {
      const result = await getInfo();
      if (result) {
        setCurrentInfo(result);
        if (result.idPackages?.length > 0) {
          setSelectedIdPackage(result.idPackages[0]);
        } else {
          setCreateWalletModalOpen(true);
        }
      } else {
        setCreateWalletModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: MetamaskActions.SetError, payload: error });
    }
  };

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setWalletInfo({ ...walletInfo, [ev.target.id]: ev.target.value });
  };

  const handleGetBalance = async () => {
    try {
      const balance = await getBalance(selectedIdPackage.publicId);
      setCurrentBalance(balance);
    } catch (error) {
      console.error(error);
      dispatch({ type: MetamaskActions.SetError, payload: error });
    }
  };

  const handleCreateWallet = async () => {
    const errors = validateCreateWalletState(walletInfo);
    if (Object.keys(errors).length > 0) {
      return dispatch({
        type: MetamaskActions.SetError,
        payload: new Error(Object.values(errors)[0]),
      });
    }
    await createNewWallet(walletInfo.name, walletInfo.seed);
    setCreateWalletModalOpen(false);
    setWalletInfo({});
    await handleGetInfo();
    return null;
  };

  useEffect(() => {
    if (isMetaMaskReady && state.installedSnap) {
      handleGetInfo().catch((error) => {
        console.error(error);
        dispatch({ type: MetamaskActions.SetError, payload: error });
      });
    }
  }, [isMetaMaskReady, state.installedSnap]);

  useEffect(() => {
    if (selectedIdPackage?.publicId) {
      handleGetBalance().catch((error) => {
        console.error(error);
        dispatch({ type: MetamaskActions.SetError, payload: error });
      });
    }
  }, [selectedIdPackage]);

  return (
    <>
      {currentInfo.idPackages?.length && (
        <Header
          currentInfo={currentInfo}
          selectedIdPackage={selectedIdPackage}
          setSelectedIdPackage={setSelectedIdPackage}
          setCreateWalletModalOpen={setCreateWalletModalOpen}
        />
      )}
      <Container>
        {state.error && (
          <div className="flex flex-col items-center justify-center">
            <ErrorMessage>
              <b>An error happened:</b> {state.error.message}
            </ErrorMessage>
          </div>
        )}
        <div className="flex flex-col items-center justify-center mt-12">
          {!isMetaMaskReady && (
            <Card
              content={{
                title: 'Install',
                description:
                  'Snaps is a software only available in MetaMask. Install MetaMask to get started.',
                button: <InstallFlaskButton />,
              }}
              fullWidth
            />
          )}
          {!state.installedSnap && isMetaMaskReady && (
            <Card
              content={{
                title: 'Connect',
                description:
                  'Get started by connecting to and installing the QUBIC wallet snap.',
                button: <ConnectButton onClick={handleConnectClick} />,
              }}
              disabled={!isMetaMaskReady}
            />
          )}
          {shouldDisplayReconnectButton(state.installedSnap) &&
            isMetaMaskReady &&
            selectedIdPackage?.publicId && (
              <WalletCard
                content={{
                  title: selectedIdPackage.name,
                  description: selectedIdPackage.publicId,
                  balance: currentBalance,
                  button: (
                    <div className="flex flex-row items-center justify-center space-x-12">
                      <SendTransactionButton
                        onClick={() => setTransactionModalOpen(true)}
                      />
                      <ViewWalletButton
                        onClick={() => setWalletModalOpen(true)}
                      />
                    </div>
                  ),
                }}
                disabled={!state.installedSnap}
              />
            )}
        </div>
        <div className="flex flex-col items-center justify-center mt-6">
          <Notice>
            Please note that this software is in active development
          </Notice>
        </div>
      </Container>
      <SendTransactionModal
        open={transactionModalOpen}
        onClose={setTransactionModalOpen}
        selectedIdPackage={selectedIdPackage}
      />
      <ViewWalletModal
        open={walletModalOpen}
        onClose={setWalletModalOpen}
        selectedIdPackage={selectedIdPackage}
      />
      <CreateWalletModal
        open={createWalletModalOpen}
        onClose={setCreateWalletModalOpen}
        canClose={currentInfo.idPackages?.length > 0}
        handleCreateWallet={handleCreateWallet}
        walletInfo={walletInfo}
        handleInputChange={handleInputChange}
      />
    </>
  );
};

export default Index;
